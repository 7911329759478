import { useEffect, useState } from 'react';
import { setupScripts } from './globalDefinitions';
import { executeNoBodyRequest } from './fetchData';

/**
 * hook to get environment name
 * We can't use the staging setting unitil we know the MIP team enables
 * every single form used by the widgets from thh staging environment.
 * /*
 * @info: use
 * `$SME$ test`
 * for Company name to prevent creating official IBM CI
 *
 * @returns {string} environment
 */

export enum environment {
  production = 'production',
  stage = 'stage',
  preview = 'preview',
}

export const useEnv = (): [string] => {
  const [env, setEnv] = useState(environment.preview);

  useEffect(() => {
    console.log(`Location:`, window.location.host);
    if (
      window.location.host === 'www.ibm.com' ||
      window.location.host ===
        'hybrid-cloud-widgets-production.s3.us.cloud-object-storage.appdomain.cloud'
    ) {
      setEnv(environment.production);
    }
    if (window.location.hostname === 'localhost') {
      console.log(`Setting stage:`, window.location.hostname);
      setEnv(environment.stage);
      setupScripts(
        'https://wwwstage.ibm.com/account/ibmidutil/widget/js/main.js',
        'https://wwwstage.ibm.com/account/ibmidutil/widget/js/loader.js',
      );
    }
  }, []);
  return [env];
};

export interface variables {
  environment: string;
  bucket: string;
  figmaToken: string;
}

let vars = {} as variables;

let variable = environment.preview;
const bucket = '';

export const getEnv = async (): Promise<[string, string, string]> => {
  let env = environment.preview;
  let bucketFile = '';
  let token = '';
  const hostingService = '/environment';
  await executeNoBodyRequest(`${hostingService}`, 'GET').then((response) => {
    //console.log(`Obtained: `, response);
    if (response.status === 200) {
      vars = response.message as unknown as variables;
      env = vars.bucket as environment;
      bucketFile = vars.bucket;
      token = vars.figmaToken;
      if (vars.environment === 'production') {
        env = environment.production;
        variable = environment.production;
      } else if (vars.environment === 'stage') {
        env = environment.stage;
        variable = environment.stage;
      }
    }
    return [env, bucketFile, token];
  });
  return [env, bucketFile, token];
};

export const getVariable = () => {
  return variable;
};

export const getBucket = () => {
  return bucket;
};
