export const DISCOUNT_INSTANCE_EST = 1 - 0.3; // Discount - 30%
export const RAM = 16;
export const YEARLY = 12;
export const EST_MIN_EMP = 1;
export const EST_MAX_EMP = 10000;
export const EST_DEFAULT_EMP = 500;
export const STD_MIN_EMP = 10000;
export const STD_MAX_EMP = 10000000;
export const STD_DEFAULT_EMP = 10000;
export const PRE_DEFAULT_EMP = 10000;
export const ESS_PN = 'D0JKTZX'; // Essentials

// UT30 is an unique code to identify each widget in the BPPR ecosystem
export const UT30RandoriPE = '30AT3';
