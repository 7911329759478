export interface DisclaimerTextProps {
  localeCode: string;
  product: string;
}

const DisclaimerText = ({ localeCode, product }: DisclaimerTextProps) => {
  const getDisclaimerText = (localeCode: string, product: string) => {
    switch (localeCode) {
      case 'us-en':
        return `Calculated prices are estimates provided for planning purposes only taking into account potential IBM discounts, and are not a formal offer from IBM or an IBM Business Partner. The estimation may reflect the total discounted price. Certain factors such as configuration, add-ons, or additional requirements will affect final price. IBM Business Partners set and provide their own IBM ${product} pricing, and neither IBM nor IBM Business Partners are bound by the provided estimate.`;
      case 'de-de':
        return `Bei den berechneten Preisen handelt es sich um Schätzungen und nicht um ein formelles Angebot von IBM oder einem IBM Geschäftspartner. Sie dienen lediglich zu Planungszwecken und berücksichtigen mögliche IBM Rabatte. Die Schätzung spiegelt möglicherweise den ermäßigten Gesamtpreis wider. Bestimmte Faktoren, wie die Konfiguration, Add-ons oder zusätzliche Anforderungen, können sich auf den Endpreis auswirken. IBM Geschäftspartner setzen ihre eigenen Preise für IBM ${product} fest und unterbreiten entsprechende Angebote. Weder IBM noch IBM Geschäftspartner sind an die genannte Schätzung gebunden.`;
      case 'es-es':
        return `Los precios calculados son estimaciones proporcionadas únicamente con fines informativos, teniendo en cuenta los posibles descuentos de IBM, y no constituyen una oferta formal de IBM o de un socio comercial de IBM. La estimación puede reflejar el precio total con descuento. Ciertos factores como la configuración, los complementos o los requisitos adicionales afectarán al precio final. Los socios comerciales de IBM fijan y proporcionan sus propios precios de IBM ${product}, y ni IBM ni los socios comerciales de IBM están sujetos a la estimación proporcionada.`;
      case 'mx-es':
        return `Los precios calculados son estimaciones proporcionadas únicamente con fines informativos, teniendo en cuenta los posibles descuentos de IBM, y no constituyen una oferta formal de IBM o de un socio comercial de IBM. La estimación puede reflejar el precio total con descuento. Ciertos factores como la configuración, los complementos o los requisitos adicionales afectarán al precio final. Los socios comerciales de IBM fijan y proporcionan sus propios precios de IBM ${product}, y ni IBM ni los socios comerciales de IBM están sujetos a la estimación proporcionada.`;
      case 'fr-fr':
        return `Les prix calculés sont des estimations fournies à des fins de planification uniquement et tiennent compte des remises IBM potentielles. Ils ne constituent pas une offre officielle d’IBM ni d’un partenaire commercial IBM. L’estimation peut refléter le prix total réduit. Certains facteurs tels que la configuration, les extensions ou les exigences supplémentaires ont une incidence sur le prix final. Les partenaires commerciaux IBM établissent et fournissent leur propre tarification pour IBM ${product}. IBM et les partenaires commerciaux IBM ne sont en aucun cas liés par l’estimation fournie.`;
      case 'id-id':
        return `Perhitungan harga merupakan perkiraan yang disediakan semata untuk tujuan perencanaan, dengan mempertimbangkan kemungkinan potongan harga dari IBM, dan bukan merupakan penawaran resmi dari IBM atau Mitra Bisnis IBM. Perkiraan ini mungkin mencerminkan total harga setelah dikenai potongan. Beberapa faktor tertentu, seperti konfigurasi, add-on, atau persyaratan tambahan akan memengaruhi harga akhir. Mitra Bisnis IBM menetapkan dan memberikan harga IBM ${product} mereka sendiri dan baik IBM maupun Mitra Bisnis IBM tidak terikat dengan estimasi yang diberikan.`;
      case 'it-it':
        return `I prezzi calcolati sono stime riportate a solo scopo di pianificazione, tenendo conto dei potenziali sconti IBM, e non rappresentano un'offerta formale da parte di IBM o di un business partner IBM. La stima può riflettere il prezzo totale scontato. Alcuni fattori, come la configurazione, i componenti aggiuntivi o i requisiti supplementari, incidono sul prezzo finale. I business partner IBM stabiliscono e forniscono il proprio prezzo per IBM ${product} e né IBM, né i business partner IBM sono vincolati alla stima fornita.`;
      case 'jp-ja':
        return `計算された金額は、潜在的なIBM割引を考慮したプラン用として提供される見積もり額であり、IBMまたはIBMビジネス・パートナーからの正式なオファーではありません。見積もりには、割引価格の合計が反映される場合があります。最終価格には、構成、アドオン、追加要件などの特定の要素が反映されます。IBMビジネス・パートナーは、IBM ${product}料金体系を独自に設定して提供しており、IBMもIBMビジネス・パートナーのいずれも、ここで提供された見積もり額に拘束されないものとします。`;
      case 'kr-ko':
        return `계산된 가격은 잠재적인 IBM 할인만을 고려하여 계획 용도로 제공된 것이며, IBM이나 IBM 비즈니스 파트너의 공식적인 제안이 아닙니다.추정치에는 총 할인 가격이 반영될 수 있습니다.최종 가격은 구성, 추가 기능, 추가 요구 사항과 같은 특정 요인에 따라 달라질 수 있습니다.IBM 비즈니스 파트너는 자체적인 IBM ${product} 견적을 제공합니다. IBM도 IBM 비즈니스 파트너도 제공된 견적에 대해서는 책임을 지지 않습니다.`;
      case 'br-pt':
        return `Os preços calculados são estimativas fornecidas apenas para fins de planejamento, levando em conta descontos potenciais da IBM, e não representam uma oferta formal da IBM ou de um parceiro de negócios da IBM. A estimativa pode refletir o preço total com desconto. Certos fatores como configuração, complementos ou requisitos adicionais afetarão o preço final. Os parceiros de negócios da IBM definem e fornecem seus próprios preços para o IBM ${product}, e nem a IBM nem os parceiros de negócios da IBM estão vinculados pela estimativa fornecida.`;
      case 'cn-zh':
        return `计算价格是出于规划目的而提供的估算费用，仅考虑了 IBM 的潜在折扣，并非 IBM 或 IBM 业务合作伙伴提供的正式报价。估算费用可反映折后总价。最终价格可能会受某些因素影响，如配置、附加组件或其他要求。IBM 合作伙伴可以自主对 IBM ${product} 进行定价和报价，估算成本对 IBM 及 IBM 合作伙伴没有约束力。`;
      default:
        return `Calculated prices are estimates provided for planning purposes only taking into account potential IBM discounts, and are not a formal offer from IBM or an IBM Business Partner. The estimation may reflect the total discounted price. Certain factors such as configuration, add-ons, or additional requirements will affect final price. IBM Business Partners set and provide their own IBM ${product} pricing, and neither IBM nor IBM Business Partners are bound by the provided estimate.`;
    }
  };
  return <>{getDisclaimerText(localeCode, product)}</>;
};

export default DisclaimerText;
