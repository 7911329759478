import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import translationsModal from './TranslationsModal.json';

export const LangDefaults = {
  CountryLanguage: 'English',
  localCurrency: 'USD',
  localCountryLang: 'us-en',
  countryCode: 'us',
  country: 'USA',
};

export type countryType = {
  countryName: string;
  countryLang: string;
  countryLanguage?: string;
  country: string;
  currency: string;
};

/**
 * Translate text for language button on ModalWrapper
 */
export const getMessage = (messages: {
  id?: string;
  defaultMessage?: string;
}) => {
  const message = <FormattedMessage {...messages} />;
  const intl = useIntl();
  // eslint-disable-next-line formatjs/enforce-id
  return intl.formatMessage({
    id: message.props.id,
    defaultMessage: message.props.defaultMessage,
  });
};

// Function to return specific translations
export const getTranslationsModal = (
  keyToSearch: string,
  lang: string | undefined,
): string => {
  if (lang) {
    const corrspondingCountryFetched = translationsModal.find((object) => {
      const translationLang = object.lang as string;
      if (lang.slice(3, 5) === translationLang) {
        console.log(`Found translation for language: ${lang}`);
        return object;
      }
    });
    if (corrspondingCountryFetched) {
      const rsponse = Object.entries(corrspondingCountryFetched).find((key) => {
        if (key[0] === keyToSearch) {
          return key[1];
        }
      });
      if (rsponse) {
        return rsponse[1];
      } else return '';
    }
  }
  return '';
};

export const useLang = (
  countryList: countryType[],
  test: boolean,
  countryTest: string,
  setCurrency: React.Dispatch<React.SetStateAction<string>>,
  setCountry: React.Dispatch<React.SetStateAction<string>>,
  localeCode?: string,
): [string, string, string?] => {
  const [lang, setLang] = useState(LangDefaults.localCountryLang); // us-en langage by default
  const [userLocale, setUserLocale] = useState(LangDefaults.localCountryLang); // us-en langage by default
  //
  let ddoCountry = LangDefaults.countryCode;
  const ddoRetrieved = new URLSearchParams(window.location.search).get(
    'ddo_country',
  );
  if (ddoRetrieved) {
    ddoCountry = ddoRetrieved;
  } else if (test === true) {
    ddoCountry = countryTest;
  } else {
    ddoCountry = window?.digitalData?.user?.location?.country.toLowerCase();
  }

  useEffect(() => {
    if (ddoCountry === null || ddoCountry === undefined) {
      ddoCountry = LangDefaults.countryCode; // Default in case it does not exists
    }
    // Look in currencies if country Lang exists
    const corrspondingCountryFetched = countryList.find((object) => {
      const lang = object.countryLang as string;
      if (lang.slice(0, 2) === ddoCountry) {
        console.log(`Detected country code: ${ddoCountry}`);
        setUserLocale(object.countryLang);
        return userLocale;
      }
    });
    if (corrspondingCountryFetched) {
      const fetchedLang = corrspondingCountryFetched
        ? corrspondingCountryFetched.countryLang
        : LangDefaults.localCountryLang;
      setUserLocale(fetchedLang);
      if (fetchedLang) {
        setLang(fetchedLang);
        setCountry(corrspondingCountryFetched.country);
        setCurrency(corrspondingCountryFetched.currency);
      }
    } else {
      setCountry(LangDefaults.country);
      setCurrency(LangDefaults.localCurrency);
    }
  }, [userLocale, ddoCountry]);
  return [lang, userLocale, ddoCountry];
};

export const determineCountry = (
  setLocalExist: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedLocale: React.Dispatch<React.SetStateAction<string>>,
  setModalLocalCountry: React.Dispatch<React.SetStateAction<string>>,
  setModalLocalLanguage: React.Dispatch<React.SetStateAction<string>>,
  setModalLocalCurrency: React.Dispatch<React.SetStateAction<string>>,
  setToggleCountry: React.Dispatch<React.SetStateAction<boolean>>,
  lang: string,
  countryList: countryType[],
  supportLocalLanguge?: boolean,
  test?: boolean, // to mock the matchurl
  localeCode?: string, // to replicate the matchurl working in storybook
) => {
  useEffect(() => {
    // For widgets to indicate if will support languages. True will support it.
    // if False is passed, it will indicate no support and the locales/extractedStrings files
    // will need to be in English.
    // Note: if the /data/currencies.json file does contain currency supports and we don't want
    // to support languaggues, we need to paas False to supportLocalLanguge.
    let localLanguageSupport = true; // defualt is to support
    if (supportLocalLanguge !== undefined) {
      localLanguageSupport = supportLocalLanguge;
    }
    setToggleCountry(false);
    // ✅ Find de-de in countries and lang from data.country
    const urlHref = window.location.href;
    const matchUrl = test
      ? ['', localeCode]
      : urlHref.match(/\/([a-z]{2}-[a-z]{2})\//i);
    countryList.map((obj) => {
      if (obj.countryLang === lang) {
        //light to deploy languages for the supported countries.
        setLocalExist(localLanguageSupport);
        // Only show button when not en
        setSelectedLocale(obj.countryLang);
        setModalLocalCountry(obj.countryName);
        if (obj.countryLanguage !== undefined) {
          setModalLocalLanguage(obj.countryLanguage);
        } else {
          setModalLocalLanguage(LangDefaults.CountryLanguage);
        }
        setModalLocalCurrency(obj.currency);
        if (lang.slice(-2) !== 'en') {
          setToggleCountry(true);
          // Hide button when match in URL
          if (matchUrl && matchUrl[1]) {
            if (matchUrl[1] === lang || lang.slice(-2) === 'en') {
              setToggleCountry(false);
            }
          }
        }
        return obj.countryLang === lang;
      }
    });
  }, [
    setLocalExist,
    setSelectedLocale,
    setModalLocalCountry,
    setModalLocalLanguage,
    setModalLocalCurrency,
    setToggleCountry,
    lang,
  ]);
};
