import { useEffect, useState } from 'react';
import { YEARLY, DISCOUNT_INSTANCE_EST, ESS_PN } from '../constants/spread';
import { getListPrice } from 'src/common/hooks/widgets/fetchData';
import { useCurrencyWithSymbol } from 'src/common/hooks/widgets/useCurrencyWithSymbol';

/**
 * Calculate the estimated price
 *
 * @param {object} intl used for the formatting
 * @param {string} currency the language code to get the currency sign for
 * @param {number} activities : Number of activities/workstations
 * @param {boolean} breach : Includes Breach
 * @returns {Array}
 */

export interface PropsFunc {
  currency: string;
  country: string;
  activities: number;
  tileValueSelected: string;
  localeCode: string;
}

export interface IFetchError {
  status: number;
  statusText: string;
}

export interface priceProps {
  partNumber: string;
  pricingIndicator: string;
  pricingModel: number;
  scaleQtyPrice: [
    {
      qty: number;
      price: number;
    },
  ];
}

interface PriceParts {
  partNumber: string;
  pricingIndicator: string;
  pricingModel: number;
  scaleQtyPrice: ScaleQtyPrice[];
}

interface ScaleQtyPrice {
  qty: number;
  price: number;
}

interface Price {
  price: number;
}

const partsToQuery = [
  {
    partNumber: ESS_PN,
  },
];

type MarketPlaceRequest = {
  currency: string;
  countryCode3: string;
  parts: typeof partsToQuery;
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const usePrice = ({
  currency,
  country,
  activities,
  tileValueSelected,
  localeCode,
}: PropsFunc) => {
  const [totalCost, setTotalCost] = useCurrencyWithSymbol(
    currency,
    localeCode,
    0,
  );
  const [totalCostMonthly, setTotalCostMonthly] = useCurrencyWithSymbol(
    currency,
    localeCode,
    0,
  );
  const [essentialsTier, setEssentialsTier] = useState(0);
  const [dataFetched, setDataFetched] = useState([]);
  const [fetchingError, setFetchingError] = useState({});
  const [loading, setLoading] = useState(false);

  const calculatedEssentialPrice = () => {
    const price = calculatePricing(activities, essentialsTier);
    return price;
  };

  const calculatePricing = (qty: number, price: number) => {
    return price + price * Math.ceil((35 * qty - 10000) / 10000);
  };

  const setPricesData = (products: priceProps[]) => {
    if (products?.length) {
      const ESS_PARTS = products.find(
        (i: PriceParts) => i.partNumber == ESS_PN,
      );

      setEssentialsTier(ESS_PARTS ? ESS_PARTS.scaleQtyPrice[0].price : 0);
    }
  };

  const fetchPrices = () => {
    setLoading(true);
    const body: MarketPlaceRequest = {
      currency: currency,
      countryCode3: country,
      parts: partsToQuery,
    };
    getListPrice(
      `https://api.marketplace.ibm.com/purchase/catalog/resources/price/info`,
      JSON.stringify(body),
      'POST',
    )
      .then((data) => {
        if (data.parts && data.parts.length !== 0) {
          setLoading(false);
          setPricesData(data.parts);
          setDataFetched(data.parts);
        } else {
          setLoading(false);
          setDataFetched([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setDataFetched([]);
        if (error.response) {
          setFetchingError(error.response);
        }
      });
  };

  const price = calculatedEssentialPrice();

  useEffect(() => {
    if (currency && dataFetched.length === 0) {
      fetchPrices();
    }
  }, [currency]);

  useEffect(() => {
    setTotalCost(price * YEARLY * DISCOUNT_INSTANCE_EST);
    setTotalCostMonthly(price * DISCOUNT_INSTANCE_EST);
  }, [activities, essentialsTier, tileValueSelected]);

  return [totalCost, totalCostMonthly, loading, fetchingError];
};
