import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { useSmallBreakpoint } from 'src/common/hooks/widgets/useBreakpoint';
import { useEnv } from 'src/common/hooks/widgets/useEnv';
import { useCookiesURL } from 'src/common/hooks/widgets/useCookiesURL';
import * as URX_FORM from '../constants/urx-form';
import {
  EST_DEFAULT_EMP,
  EST_MAX_EMP,
  EST_MIN_EMP,
  PRE_DEFAULT_EMP,
  STD_DEFAULT_EMP,
  STD_MAX_EMP,
  STD_MIN_EMP,
  UT30RandoriPE,
} from '../constants/spread';
import { Helmet } from 'react-helmet';
import {
  Button,
  ButtonSet,
  InlineNotification,
  ModalWrapper,
  NumberInput,
  RadioTile,
  SkeletonPlaceholder,
  Tag,
  TileGroup,
  Tooltip,
} from 'carbon-components-react';
import './RandoriPriceEstimator.scss';
import 'src/common/style/common.scss';
import { usePrice } from '../hooks/usePrice';
import Cookies from 'js-cookie';
import Footnote from './Footnote';
import { FormModal } from 'src/common/utils/FormModal';
import messages from '../locales/messages';
import { FormattedMessage, useIntl } from 'react-intl';
import Thanks from './Thanks';
import { segmentsTracking } from '../lib/tracker';
import { initUrxForm } from 'src/common/hooks/widgets/urxForm';
import { swapCountryAndLanguage } from 'src/common/utils/swapCountryAndLanguage';
import { useBPName } from 'src/common/hooks/widgets/useBPName';
import countryList from '../services/currencies.json';
import BusinessPartner from 'src/common/components/BusinessPartner/BusinessPartner';
import {
  LangDefaults,
  determineCountry,
  getMessage,
  useLang,
} from 'src/common/hooks/widgets/useLang';
import DisclaimerText from 'src/common/components/DisclaimerText/DisclaimerText';

export interface RandoriPriceEstimatorProps {
  langCode: string;
  test: boolean;
  countryToTest: string;
}

const RandoriPriceEstimator = ({
  langCode,
  test,
  countryToTest,
}: RandoriPriceEstimatorProps) => {
  const localeCode = swapCountryAndLanguage(langCode as IBMLocale);
  const [urxlang] = useState(localeCode.toString());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currency, setCurrency] = useState('');
  const [country, setCountry] = useState('');
  const [showPrice, setShowPrice] = useState(true);
  // lang and userlocale is based out of ddo.
  // lang and userlocale variable will be use once we start implmenting multilanguage
  const [lang, userLocale] = useLang(
    countryList,
    test,
    countryToTest,
    setCurrency,
    setCountry,
    localeCode,
  );
  const [localCurrency, setlocalCurrency] = useState<string>(localeCode);

  const [toggleCountry, setToggleCountry] = useState(false);
  const [modalLocalCountry, setModalLocalCountry] = useState('');
  const [modalLocalCurrency, setModalLocalCurrency] = useState('USD');
  const [modalLocalLanguage, setModalLocalLanguage] = useState('English');

  const [localExist, setLocalExist] = useState(false);
  let localStorageFound = localStorage.getItem('langLocale');
  if (localStorageFound === null)
    localStorageFound = LangDefaults.localCountryLang;
  const [selectedLocale, setSelectedLocale] = useState(localStorageFound);

  // Determine Country
  determineCountry(
    setLocalExist,
    setSelectedLocale,
    setModalLocalCountry,
    setModalLocalLanguage,
    setModalLocalCurrency,
    setToggleCountry,
    lang,
    countryList,
    true,
    test,
    localeCode,
  );

  const isSmall = useSmallBreakpoint('560px');
  const [environment] = useEnv();
  const [formComplete, setFormComplete] = useState(false);
  const [tileValueSelected, setTileValueSelected] = useState('essentials');
  const [packageName, setPackageName] = useState('Essentials');
  const [activities, setEmployee] = useState(EST_DEFAULT_EMP);
  const activitiesRef = useRef() as MutableRefObject<HTMLInputElement>;
  const cookieURL = useCookiesURL();
  const [totalCost, totalCostMonthly, loading] = usePrice({
    currency,
    country,
    activities,
    tileValueSelected,
    localeCode,
  });

  const { bpName, logo, companyId, desc } = useBPName(UT30RandoriPE, true);

  const minEmployee =
    tileValueSelected === 'essentials' ? EST_MIN_EMP : STD_MIN_EMP;

  const maxEmployee =
    tileValueSelected === 'essentials' ? EST_MAX_EMP : STD_MAX_EMP;

  const invalidEmployee =
    parseInt(activitiesRef?.current?.value) < minEmployee ||
    parseInt(activitiesRef?.current?.value) > maxEmployee ||
    activitiesRef?.current?.value === '';

  window.urxEnvironment = environment;
  window.onUrxFormSubmitSuccess = () => {
    setFormComplete(!formComplete);
    setDrawerOpen(false); // Hide Drawer on Thank you page
  };

  useEffect(() => {
    setlocalCurrency(userLocale); // show local Currency
  }, [userLocale, localeCode, localCurrency]);

  useEffect(() => {
    setCookie();
  }, [activities, totalCostMonthly, totalCost, packageName]);

  useEffect(() => {
    if (tileValueSelected === 'standard') {
      setEmployee(STD_DEFAULT_EMP);
    } else if (tileValueSelected === 'essentials') {
      setEmployee(EST_DEFAULT_EMP);
    } else {
      setEmployee(PRE_DEFAULT_EMP);
    }
  }, [tileValueSelected]);

  const setCookie = () => {
    Cookies.set(
      'urxdcq',
      JSON.stringify({
        source: URX_FORM.FORM_ID,
        // One field to provide all values (stringify)
        dcq: {
          Q_NOTESDATA:
            cookieURL +
            ' Employee: ' +
            activities +
            ' | Package: ' +
            packageName +
            ' | Estimated Monthly cost:' +
            totalCostMonthly +
            ' | Estimated Annual cost:' +
            totalCost,
        },
      }),
    );
  };

  const intl = useIntl();

  const essentialsFeature = [
    intl.formatMessage(messages.est_feat_pt1),
    intl.formatMessage(messages.est_feat_pt2),
    intl.formatMessage(messages.est_feat_pt3),
  ];

  const standardFeature = [
    intl.formatMessage(messages.std_feat_pt1),
    intl.formatMessage(messages.std_feat_pt2),
    intl.formatMessage(messages.std_feat_pt3),
  ];

  const premiumFeature = [
    intl.formatMessage(messages.prem_feat_pt1),
    intl.formatMessage(messages.prem_feat_pt2),
  ];

  const premNotificationLabel = getMessage(messages.prem_notif_label);

  return (
    <div className="randori widget-styles">
      <Helmet>
        <script
          type="application/javascript"
          src={`https://www.ibm.com/account/ibmidutil/widget/js/loader.js`}
        ></script>
        <script
          type="application/javascript"
          src={`https://www.ibm.com/account/ibmidutil/widget/js/main.js`}
        ></script>
      </Helmet>
      {formComplete ? (
        <Thanks
          activities={activities}
          licensePriceMonthly={`${totalCostMonthly}`}
          licensePriceYearly={`${totalCost}`}
          packageName={packageName}
          bpName={bpName}
        />
      ) : (
        <div id="RandoriSaaSGrid">
          {bpName ? (
            <BusinessPartner bpName={bpName} logo={logo} desc={desc} />
          ) : null}
          <div className="bx--row">
            <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-4">
              <div className="bx--row">
                <div
                  className="bx--col-lg-4 bx--col-md-8 bx--col-sm-8 mar1 bx--offset-lg-12"
                  id="countryModal"
                >
                  {localExist && toggleCountry ? (
                    <ModalWrapper
                      modalLabel={`Found country ${modalLocalCountry}. Access page in ${modalLocalLanguage} (${modalLocalCurrency}) `}
                      modalHeading={`The QRadar estimator is available in ${modalLocalLanguage} with local currency ${modalLocalCurrency}`}
                      size={'sm'}
                      primaryButtonText={'Cool! Take me there'}
                      secondaryButtonText={''}
                      buttonTriggerText={`Access page in ${modalLocalLanguage}`}
                      handleOpen={() => {
                        segmentsTracking('CTA Clicked', {
                          pageTitle: 'Randori Recon Pricing Page',
                          object: 'Pricing page',
                          resultValue: 'CTA clicked',
                          CTA: 'Modal open ' + modalLocalCountry,
                          location: 'Estimator',
                          action: '',
                          field: '',
                        });
                      }}
                      handleSubmit={() => {
                        localStorage.setItem('langLocale', selectedLocale);
                        (window.location.href =
                          'https://www.ibm.com/' +
                          selectedLocale +
                          '/products/randori-recon/pricing'),
                          segmentsTracking('CTA Clicked', {
                            pageTitle: 'Randori Recon Pricing Page',
                            object: 'Pricing page',
                            resultValue: 'CTA clicked',
                            CTA: 'Switched to country ' + modalLocalCountry,
                            location: 'Estimator',
                            action: '',
                            field: '',
                          });
                        return true;
                      }}
                    ></ModalWrapper>
                  ) : null}
                </div>
              </div>
              <div className="bx--row mar3">
                <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-4">
                  <div className="page_sub_heading marginleft_1">
                    <FormattedMessage {...messages.rd_headline} />
                  </div>
                </div>
              </div>
              <div className="bx--row mar3">
                <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-4">
                  <p className="page_body marginleft_1">
                    <FormattedMessage {...messages.rd_sub_headline} />
                  </p>
                </div>
              </div>
              <div className="bx--row mar3">
                <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-4">
                  <TileGroup
                    className="randori__tilegroup"
                    name="radio tile group"
                    valueSelected={tileValueSelected}
                  >
                    <RadioTile
                      className="randori__tile"
                      id="radio-tile-1"
                      value="essentials"
                      onClick={() => {
                        setTileValueSelected('essentials');
                        setPackageName('Essentials');
                        segmentsTracking('CTA Clicked', {
                          pageTitle: 'Randori Recon Pricing Page',
                          object: 'Pricing page',
                          resultValue: 'CTA clicked',
                          CTA: 'Essentials Tile',
                          location: 'Estimator',
                          action: '',
                          field: '',
                        });
                      }}
                    >
                      <div className="page_sub_heading mar9">
                        <FormattedMessage {...messages.est_label} />
                      </div>
                      <div className="page_body_2">
                        <FormattedMessage {...messages.est_pkg_desc} />
                      </div>
                    </RadioTile>
                    <RadioTile
                      id="radio-tile-2"
                      value="standard"
                      className="randori__tile"
                      onClick={() => {
                        setTileValueSelected('standard');
                        setPackageName('Standard');
                        segmentsTracking('CTA Clicked', {
                          pageTitle: 'Randori Recon Pricing Page',
                          object: 'Pricing page',
                          resultValue: 'CTA clicked',
                          CTA: 'Standard Tile',
                          location: 'Estimator',
                          action: '',
                          field: '',
                        });
                      }}
                    >
                      <div className="page_sub_heading mar9">
                        <FormattedMessage {...messages.std_label} />
                      </div>
                      <div className="page_body_2">
                        <FormattedMessage {...messages.std_pkg_desc} />
                      </div>
                    </RadioTile>
                    <RadioTile
                      id="radio-tile-3"
                      value="premium"
                      onClick={() => {
                        setTileValueSelected('premium');
                        setPackageName('Premium');
                        segmentsTracking('CTA Clicked', {
                          pageTitle: 'Randori Recon Pricing Page',
                          object: 'Pricing page',
                          resultValue: 'CTA clicked',
                          CTA: 'Premium Tile',
                          location: 'Estimator',
                          action: '',
                          field: '',
                        });
                      }}
                    >
                      <div className="page_sub_heading mar9">
                        <FormattedMessage {...messages.prem_label} />
                      </div>
                      <div className="page_body_2">
                        <FormattedMessage {...messages.prem_pkg_desc} />
                      </div>
                    </RadioTile>
                  </TileGroup>
                </div>
              </div>
              {/* User Part */}
              <div className="bx--row mar3">
                <div className="bx--col-lg-9 bx--col-md-16 bx--col-sm-16 mar2">
                  <div className="mar6">
                    {tileValueSelected === 'essentials' ? (
                      <>
                        <div className="page_body_2 marginleft_1">
                          <FormattedMessage {...messages.est_feat_label} />:
                        </div>
                        <div className="marginleft_1">
                          {essentialsFeature.map(
                            (item: string, index: number) => (
                              <Tag
                                key={index}
                                type={'purple'}
                                size="md"
                                disabled={false}
                                filter={false}
                                className="tag-margin"
                              >
                                {item}
                              </Tag>
                            ),
                          )}
                        </div>
                      </>
                    ) : tileValueSelected === 'standard' ? (
                      <>
                        <div className="page_body_2 marginleft_1">
                          <FormattedMessage {...messages.std_feat_label} />:
                        </div>
                        <div className="marginleft_1">
                          {standardFeature.map(
                            (item: string, index: number) => (
                              <Tag
                                key={index}
                                type={'purple'}
                                size="md"
                                disabled={false}
                                filter={false}
                                className="tag-margin"
                              >
                                {item}
                              </Tag>
                            ),
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="page_body_2 marginleft_1">
                          <FormattedMessage {...messages.prem_feat_label} />:
                        </div>
                        <div className="marginleft_1">
                          {premiumFeature.map((item: string, index) => (
                            <Tag
                              key={index}
                              type={'purple'}
                              size="md"
                              disabled={false}
                              filter={false}
                              className="tag-margin"
                            >
                              {item}
                            </Tag>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="bx--row mar6 mar1">
                    <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
                      <div className="bx--row">
                        <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
                          <Tooltip
                            onChange={() => {
                              segmentsTracking('CTA Clicked', {
                                pageTitle: 'Randori Recon Pricing Page',
                                object: 'Pricing page',
                                resultValue: 'CTA clicked',
                                CTA: 'Authorized User tooltip',
                                location: 'Estimator',
                                action: '',
                                field: '',
                              });
                            }}
                            direction={isSmall ? 'bottom' : 'right'}
                            tabIndex={0}
                            triggerText={
                              <span className="page_body marginleft_1">
                                <FormattedMessage {...messages.slider1_text} />
                              </span>
                            }
                          >
                            <span className="slide_text">
                              <FormattedMessage
                                {...messages.tooltip_users_text}
                              />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="bx--row mt-05">
                        <div className="bx--col-lg-8 bx--col-md-16 bx--col-sm-16">
                          <NumberInput
                            id="activities"
                            size="lg"
                            allowEmpty={true}
                            ref={activitiesRef}
                            invalidText={
                              <FormattedMessage
                                {...messages.invalid_activities_text}
                              />
                            }
                            className="number-input marginleft_1 pad-right-2"
                            disabled={
                              tileValueSelected === 'premium' ||
                              tileValueSelected === 'standard'
                            }
                            min={minEmployee}
                            max={maxEmployee}
                            value={activities}
                            step={100}
                            onChange={() => {
                              segmentsTracking('CTA Clicked', {
                                pageTitle: 'Randori Recon Pricing Page',
                                object: 'Pricing page',
                                resultValue: 'CTA clicked',
                                CTA: 'Total Employee',
                                location: 'Estimator',
                                action: '',
                                field: '',
                              });
                              setEmployee(Number(activitiesRef.current.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="footnote" className="div-only-lg">
                    <div className="bx--row">
                      <div className="bx--col-lg-16 marginleft_1">
                        <div>
                          <Footnote numNote={1} />
                        </div>
                        <div className="footnote_text">
                          <DisclaimerText
                            localeCode={localeCode}
                            product="Randori Recon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bx--col-lg-1"></div>
                <div className="bx--col-lg-6 bx--col-md-16 bx--col-sm-16 pad-right-2 pad-left-2">
                  {tileValueSelected === 'essentials' ? (
                    <Tag type="outline" className="essential-tag">
                      <FormattedMessage {...messages.est_label} />
                    </Tag>
                  ) : tileValueSelected === 'standard' ? (
                    <Tag type="outline" className="tag-margin1">
                      <FormattedMessage {...messages.std_label} />
                    </Tag>
                  ) : (
                    <Tag type="outline" className="tag-margin1">
                      <FormattedMessage {...messages.prem_label} />
                    </Tag>
                  )}
                  {tileValueSelected === 'premium' ||
                  tileValueSelected === 'standard' ? (
                    <div className="tag-margin2 mar-left-zero-sm">
                      <InlineNotification
                        kind="info"
                        role="onprem"
                        title={premNotificationLabel}
                        subtitle={
                          <FormattedMessage {...messages.contact_info} />
                        }
                        statusIconDescription="On-Prem Info"
                        hideCloseButton={true}
                        className="mar-left-zero-sm"
                      />

                      <ButtonSet
                        className="randori__stack-button btn-set mar4"
                        stacked
                      >
                        <Button
                          id="request_btn"
                          target="_self"
                          data-ibm-contact="scheduler-link"
                          href="#"
                          onClick={() => {
                            segmentsTracking('CTA Clicked', {
                              pageTitle: 'Randori Recon Pricing Page',
                              object: 'Pricing page',
                              resultValue: 'CTA clicked',
                              CTA: 'Book a Consultation',
                              location: 'Estimator',
                              action: '',
                              field: '',
                            });
                            window.open('#', '_self');
                          }}
                        >
                          <FormattedMessage {...messages.book_text} />
                        </Button>
                      </ButtonSet>
                    </div>
                  ) : (
                    <div id="stickyNav" className="bx--row">
                      <div className="sticky-nav price_table_comp bg_white">
                        <table className="cds--data-table cds--data-table--sm">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="price_table_comp__heading">
                                  <FormattedMessage {...messages.col1_text} />
                                </div>
                              </th>
                              <th scope="col">
                                <div className="cds--table-header-label">
                                  <div className="price_table_comp__heading">
                                    <FormattedMessage {...messages.col2_text} />
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody aria-live="polite">
                            <tr>
                              <td>
                                {' '}
                                <div className="price_table_comp__requirement price_table_comp_color padding_8">
                                  <FormattedMessage
                                    {...messages.entities_text}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="price_table_comp__quantity padding_8">{`${activities}`}</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div id="price_toggle_one">
                          {showPrice ? (
                            <div>
                              {!invalidEmployee && (
                                <>
                                  <p
                                    id="widget.estimated-cost"
                                    className="randori__ibm-type-e estmt-cost price_table_comp__bodycopy padding_24_2"
                                  >
                                    <FormattedMessage
                                      {...messages.estimated_text}
                                    />
                                    <sup className="randori__sup">1</sup>
                                  </p>

                                  <div className="padding_8 price_table_comp__price">
                                    {loading ? (
                                      <SkeletonPlaceholder className="randori__sticky-nav-skeleton" />
                                    ) : (
                                      totalCostMonthly
                                    )}
                                  </div>

                                  <p
                                    id="widget.estimated-cost"
                                    className="randori__ibm-type-e estmt-cost price_table_comp__bodycopy"
                                  >
                                    <FormattedMessage
                                      {...messages.estimated_annual_text}
                                    />
                                    <sup className="randori__sup">1</sup>
                                  </p>

                                  <div className="padding_8 price_table_comp__price">
                                    {loading ? (
                                      <SkeletonPlaceholder className="randori__sticky-nav-skeleton" />
                                    ) : (
                                      totalCost
                                    )}
                                  </div>

                                  <p className="price_table_comp__disclaimer price_table_comp_color padding_24_1">
                                    *{' '}
                                    <FormattedMessage
                                      {...messages.prcie_text}
                                    />
                                  </p>
                                  <ButtonSet
                                    className="randori__stack-button randori__bluehighlight"
                                    stacked
                                  >
                                    <Button
                                      id="request_btn"
                                      onClick={() => {
                                        segmentsTracking('CTA Clicked', {
                                          pageTitle:
                                            'Randori Recon Pricing Page',
                                          object: 'Pricing page',
                                          resultValue: 'CTA clicked',
                                          CTA: 'Request a quote now',
                                          location: 'Estimator',
                                          action: '',
                                          field: '',
                                        });
                                        initUrxForm(
                                          environment,
                                          URX_FORM.INSTANCE_ID,
                                          URX_FORM.FORM_ID,
                                          urxlang as IBMLocale,
                                          companyId,
                                        );
                                        setDrawerOpen(true);
                                        setCookie();
                                      }}
                                    >
                                      <FormattedMessage
                                        {...messages.quote_text}
                                      />
                                    </Button>
                                  </ButtonSet>
                                </>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                  {tileValueSelected !== 'premium' && (
                    <div id="footnote" className="div-only-media">
                      <div className="bx--row mar6">
                        <div className="bx--col-lg-9">
                          <div>
                            <Footnote numNote={1} />
                          </div>
                          <div className="footnote_text">
                            <DisclaimerText
                              localeCode={localeCode}
                              product="Randori Recon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <FormModal
                    pageTitle="Randori Recon Pricing Page"
                    object="Randori Recon Pricing Page"
                    productTitle={'Randori Recon Pricing'}
                    pageUrl={''}
                    bpName={bpName}
                    logo={logo}
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    location={'Estimator'}
                    formId={URX_FORM.FORM_ID}
                    lang={lang}
                  ></FormModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RandoriPriceEstimator;
