module.exports = {
  entry: './src/apps/randori-price-estimator/index.tsx',
  shortcode: 'randori-price-estimator',
  title: 'Randori Price Estimator',
  status: 'stable',
  description: 'Allows users to determine a price.',
  preview: {
    url: `${process.env.PUBLIC_URL}/storybook/iframe.html?id=widgets-randori-price-estimator-components--default&viewMode=story`,
  },
  useExternalPeerDependencies: ['react', 'react-dom', 'react-intl'],
  additionalCustomProperties: {
    webSegmentPaths: ['/cloud'],
    availableTranslations: [
      'de',
      'en',
      'es',
      'esla',
      'fr',
      'it',
      'ja',
      'ko',
      'pt',
      'zh-cn',
    ],
    org: 'digitalsales', // to filter the widget
    widgetLocation: '/pricing', // default '/pricing' but sometimes '/'
    productName: 'randori-recon', // the product name in the URL
    activatedCountryPages: [
      'us-en',
      'cn-zh',
      'br-pt',
      'de-de',
      'es-es',
      'fr-fr',
      'id-id',
      'it-it',
      'jp-ja',
      'kr-ko',
      'mx-es',
    ],
  },
};
