import { defineMessages } from 'react-intl';

const messages = defineMessages({
  return_est_text: {
    id: '8fgwsI',
    defaultMessage: 'Return to price estimator',
  },
  prem_feat_label: {
    id: '5ky40J',
    defaultMessage: 'All the features of Standard, plus',
  },
  prcie_text: {
    id: 'cVxiy4',
    defaultMessage: 'Prices shown do not include tax',
  },
  entities_text: {
    id: 'CFLecQ',
    defaultMessage: 'Entities',
  },
  bp_desc: {
    id: 'mOpKIr',
    defaultMessage:
      'I consent to IBM providing my business contact information:',
  },
  contact_info: {
    id: 'R6Ef/+',
    defaultMessage: 'For a solution of this size please get in touch with us.',
  },
  close_btn_text: {
    id: 'rbrahO',
    defaultMessage: 'Close',
  },
  est_label: {
    id: 'eXbuB9',
    defaultMessage: 'Essentials',
  },
  slider1_text: {
    id: 'm84eae',
    defaultMessage: 'Total number of external IPs and Hostnames',
  },
  est_pkg_desc: {
    id: 'JHKeRh',
    defaultMessage:
      'Get started with attack surface management. Our Essentials package provides continuous discovery to uncover your organizational exposures.',
  },
  est_feat_pt1: {
    id: 'uPbAHE',
    defaultMessage: 'External Discovery',
  },
  std_feat_pt3: {
    id: '/ALK5y',
    defaultMessage: 'API Access',
  },
  std_feat_pt1: {
    id: 'LTa7dT',
    defaultMessage: 'Enterprise Integrations',
  },
  est_feat_pt3: {
    id: 'FOGjB8',
    defaultMessage: 'Risk-Based Prioritization',
  },
  std_feat_pt2: {
    id: 'Ij3gVY',
    defaultMessage: 'Custom Policies',
  },
  est_feat_pt2: {
    id: '5dsEB0',
    defaultMessage: 'Discovery Path',
  },
  est_feat_label: {
    id: 'ZXp0z1',
    defaultMessage: 'Features',
  },
  estimated_text: {
    id: 'wNB/GH',
    defaultMessage: 'Estimated monthly cost',
  },
  prem_feat_pt2: {
    id: 't9o+/c',
    defaultMessage: 'Continuous Testing',
  },
  quote_text: {
    id: 'r4tIGb',
    defaultMessage: 'Request a quote',
  },
  req_detail: {
    id: '0L49M8',
    defaultMessage: 'Request details:',
  },
  prem_feat_pt1: {
    id: 'bRM17z',
    defaultMessage: 'Vulnerability Validation',
  },
  std_feat_label: {
    id: 'bMhVwm',
    defaultMessage: 'All the features of Essentials, plus',
  },
  package_label: {
    id: 'RcNFA2',
    defaultMessage: 'Package',
  },
  invalid_activities_text: {
    id: 'rTTwp+',
    defaultMessage: 'Please provide a entities range between 1 - 10,000',
  },
  col2_text: {
    id: 'qVGRIE',
    defaultMessage: 'Quantity',
  },
  prem_pkg_desc: {
    id: 'toHh93',
    defaultMessage:
      'Continuously validate exposures on your attack surface. Our Premium package enhances your experience by offering powerful external security testing capabilities. These features are designed to confirm the existence and exploitability of vulnerabilities on your attack surface. Verify external risk exposure and exploitability with precision.',
  },
  std_label: {
    id: 'cb2kY6',
    defaultMessage: 'Standard',
  },
  received_text: {
    id: 'PwjLGb',
    defaultMessage: 'We have received your request',
  },
  prem_label: {
    id: 'C5xzTC',
    defaultMessage: 'Premium',
  },
  env_text: {
    id: 'K7kuIZ',
    defaultMessage: 'Environment',
  },
  std_pkg_desc: {
    id: 'Iq2Z40',
    defaultMessage:
      'Operationalize attack surface management. Our Standard package comes complete with enterprise-ready features such as API access, integrations marketplace, customizable policies and workflows.',
  },
  col1_text: {
    id: 'xkB+8Z',
    defaultMessage: 'Unit',
  },
  book_text: {
    id: 'KTmxQU',
    defaultMessage: 'Book a consultation',
  },
  thank_you: {
    id: 'aYIUar',
    defaultMessage: 'Thank you!',
  },
  rd_sub_headline: {
    id: 'pfUwr3',
    defaultMessage: 'Estimate total cost of subscription',
  },
  rd_headline: {
    id: '8avn94',
    defaultMessage: 'Randori Recon ASM',
  },
  bp_title: {
    id: 'BkHBGW',
    defaultMessage: 'Selected Business Partner',
  },
  estimated_annual_text: {
    id: 'QsT4P9',
    defaultMessage: 'Estimated annual cost',
  },
  prem_notif_label: {
    id: 'vt9nHB',
    defaultMessage: 'Information notification',
  },
  tooltip_users_text: {
    id: 'ddfCuf',
    defaultMessage:
      'A unique person employed in or otherwise paid by or acting on behalf of Client’s Enterprise, whether given access to the Cloud Services or not.',
  },
});

export default messages;
